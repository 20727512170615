import { Box, Button, Grid } from '@mui/material';
import { DieselDataCapturingStationary } from './DieselDataCapturingStationary';
import { DieselDataCapturingMobile } from './DieselDataCapturingMobile';

import { useState } from 'react';
// import { getStationaryDieselData } from '../../../../../actions/carbonfootprint-rework/01-direct-ghg-emissions-and-removals/01-diesel-actions';

export default function Diesel() {
    const [selected, setSelected] = useState('Diesel - Stationary')
    const dieselCategories = ['Diesel - Stationary', 'Diesel - Mobile']
    // const dispatch = useDispatch()

    // dispatch(getStationaryDieselData())


    return (
        <>
            <Grid item xs={3}>
                <Box marginTop={2}>
                    {dieselCategories.map((c, i) => {
                        return (
                            <Button
                                key={i}
                                variant={c === selected ? 'contained' : 'outlined'}
                                size='large'
                                sx={{ width: '100%', display: 'block', mb: 2.5 }}
                                onClick={() => {
                                    setSelected(c)
                                    localStorage.setItem('catOne', c)
                                }}
                            >
                                {c}
                            </Button>
                        )
                    })
                    }
                </Box>
            </Grid>
            <Grid item xs={9}>
                {selected === 'Diesel - Stationary' ?
                    <DieselDataCapturingStationary /> :
                    <DieselDataCapturingMobile />
                }
            </Grid>
        </>
    );
}
