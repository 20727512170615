import React, { useEffect, useState } from 'react'
import { Typography, CircularProgress, Box, TextField, Button, TableContainer, Table, TableHead, TableCell, TableRow, Paper, TableBody, Select, MenuItem, FormControl } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useDispatch, useSelector } from 'react-redux';
import { getBiocharProductionFuelUse, postBiocharProductionFuelUse } from '../../../../../actions/tigercat-burns/tigercat-actions';
import { getAllProjectsWithAssignedAssets } from '../../../../../actions/tigercat-burns/tigercat-base-actions';
import moment from 'moment';

const BiocharProductionFuel = ({ projectId }) => {

    const [biocharProductionFuelState, setBiocharProductionFuelState] = useState([])
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const projectsWithAssignedEquipment = useSelector(state => state.tigercatBurnsBaseReducer.projectsWithAssignedAssets.data);
    const biocharProductionFuelUses = useSelector(state => state.tigercatBurnsReducer.biocharProductionFuelUses.data);
    const { userData } = useSelector(state => state.auth);
    const userCompanyId = userData?.tigercatBurnsUser?.companyId;

    const handleAddItem = () => {
        setBiocharProductionFuelState((prevState) => {
            const state = [...prevState]
            let id;
            if (state.length === 0) {
                id = null
            } else {
                id = state[state.length - 1].id + 1
            }
            const formData = {
                id: id,
                date: null,
                equipment: null,
                fuelUsage: null,
                hoursOperated: null,
                fuelConsumed: null,
                runningTotal: null
            };
            state.push(formData)
            return state
        })
    }

    useEffect(() => {
        const fuelUses = biocharProductionFuelUses?.map((item) => ({
            ...item,
            disabled: true
        }));
        setBiocharProductionFuelState(fuelUses);
    }, [biocharProductionFuelUses])

    useEffect(() => {
        console.log('project id change', projectId)
        dispatch(getBiocharProductionFuelUse(projectId));
        dispatch(getAllProjectsWithAssignedAssets(userCompanyId, 'equipment'))
    }, [dispatch, userCompanyId, projectId])

    const handleDateSelection = (date, dataId) => {
        setBiocharProductionFuelState((prevState) => {
            return prevState.map((item) => {
                if (item.id === dataId) {
                    const formattedDate = date.toISOString().split('T')[0];
                    item.date = formattedDate;
                }
                return item;
            });
        });
    }

    const handleEquipmentChange = (e, dataId) => {
        setBiocharProductionFuelState((prevState) => {
            return prevState.map((item) => {
                if (item.id === dataId) {
                    item.equipment = e.target.value;
                }
                return item;
            });
        });
    }

    const handleFuelUsageChange = (e, dataId) => {
        setBiocharProductionFuelState((prevState) => {
            return prevState.map((item) => {
                if (item.id === dataId) {
                    item.fuelUsage = e.target.value;
                }
                return item;
            });
        });
    }

    const handleHoursOperatedPerDayChange = (e, dataId) => {
        setBiocharProductionFuelState((prevState) => {
            return prevState.map((item) => {
                if (item.id === dataId) {
                    item.hoursOperated = e.target.value;
                }
                return item;
            });
        });
    }

    const handleSubmitData = () => {
        dispatch(postBiocharProductionFuelUse(projectId, biocharProductionFuelState)).then(() => {
            dispatch(getBiocharProductionFuelUse(projectId))
            setLoading(false)
        })
    }

    return (
        <>
            <h2>Diesel</h2>
            <TableContainer key={0} component={Paper} >
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">
                                Date
                            </TableCell>
                            <TableCell align="left">
                                Equipment
                            </TableCell>
                            <TableCell align="left">
                                Fuel Usage (gal/hr)
                            </TableCell>
                            <TableCell align="left">
                                Hours operated per day
                            </TableCell>
                            <TableCell align="left">
                                Fuel consumed per day (gal/day)
                            </TableCell>
                            <TableCell align="left">
                                Running Total (gal)
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* {console.log(biocharProductionFuelState)} */}
                        {biocharProductionFuelState?.map((data, index) => {
                            return (
                                <TableRow key={index}>
                                    <TableCell align="left">
                                        <FormControl>
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <DatePicker name={'date'}
                                                    value={data.date ? moment(data.date) : null}
                                                    onChange={(e) => handleDateSelection(e, data.id)}
                                                    disabled={data.disabled}
                                                />
                                            </LocalizationProvider>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell align="left">
                                        <FormControl sx={{ width: '200px' }}>
                                            {/* <InputLabel id="demo-simple-select-label">{data.equipment}</InputLabel> */}
                                            <Select name='equipment' value={data.equipment === null ? '' : data.equipment} fullWidth onChange={(e) => handleEquipmentChange(e, data.id)}
                                                disabled={data.disabled}>
                                                {projectsWithAssignedEquipment?.filter((project) => project.id === projectId)[0]?.equipment?.map(equip => {
                                                    return (
                                                        <MenuItem key={equip._id} value={equip._id}>
                                                            {equip.name}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell align="left">
                                        <TextField
                                            name='fuel usage'
                                            value={data.fuelUsage === null ? '' : data.fuelUsage}
                                            onChange={(e) => handleFuelUsageChange(e, data.id)}
                                            type="text"
                                            disabled={data.disabled}
                                        />
                                    </TableCell>
                                    <TableCell align="left">
                                        <TextField
                                            name='hours operated'
                                            value={data.hoursOperated === null ? '' : data.hoursOperated}
                                            onChange={(e) => handleHoursOperatedPerDayChange(e, data.id)}
                                            type="text"
                                            disabled={data.disabled}
                                        />
                                    </TableCell>
                                    <TableCell align="left">
                                        {data.fuelConsumed === null ? <Typography sx={{ color: 'darkorange' }}>Undetermined</Typography> : data.fuelConsumed}
                                    </TableCell>
                                    <TableCell align="left">
                                        {data.runningTotal === null ? <Typography sx={{ color: 'darkorange' }}>Undetermined</Typography> : data.runningTotal}
                                    </TableCell>
                                </TableRow>
                            )
                        })}

                    </TableBody>
                </Table>
            </TableContainer>
            <Box sx={{ position: 'relative', mb: 10 }}>
                {loading ? <CircularProgress sx={{ position: 'absolute', left: '125px', top: '12px' }}></CircularProgress> :
                    <Button variant='contained' sx={{ height: 'auto', mt: 2 }} onClick={handleAddItem}>Add Entry</Button>}
                <Button variant='contained' sx={{ height: 'auto', mt: 2, ml: 3 }} onClick={handleSubmitData}>Submit Data</Button>
            </Box>
        </>
    )
}

export default BiocharProductionFuel