import {
    GET_TIGERCAT_BURNS_BIOCHAR_VOLUMES_SUCCESS,
    GET_TIGERCAT_BURNS_BIOCHAR_VOLUMES_FAIL,
    POST_TIGERCAT_BURNS_POST_BULK_FUEL_DELIVERY_SUCCESS,
    POST_TIGERCAT_BURNS_POST_BULK_FUEL_DELIVERY_FAIL,
    GET_TIGERCAT_BURNS_GET_BULK_FUEL_DELIVERY_SUCCESS,
    GET_TIGERCAT_BURNS_GET_BULK_FUEL_DELIVERY_FAIL,
    POST_TIGERCAT_BURNS_POST_BIOCHAR_PRODUCTION_FUEL_USE_SUCCESS,
    POST_TIGERCAT_BURNS_POST_BIOCHAR_PRODUCTION_FUEL_USE_FAIL,
    GET_TIGERCAT_BURNS_GET_BIOCHAR_PRODUCTION_FUEL_USE_SUCCESS,
    GET_TIGERCAT_BURNS_GET_BIOCHAR_PRODUCTION_FUEL_USE_FAIL
} from '../../actions/tigercat-burns/types/tigercat-types';

const initialState = {
    biocharVolumes: [],
    bulkFuelDeliveries: [],
    biocharProductionFuelUses: [],
    loading: true,
};

export default function tigercatBurnsReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_TIGERCAT_BURNS_BIOCHAR_VOLUMES_SUCCESS:
            return {
                ...state,
                loading: false,
                biocharVolumes: payload
            };
        case GET_TIGERCAT_BURNS_BIOCHAR_VOLUMES_FAIL:
            return {
                ...state,
                loading: false
            };
        case POST_TIGERCAT_BURNS_POST_BULK_FUEL_DELIVERY_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case POST_TIGERCAT_BURNS_POST_BULK_FUEL_DELIVERY_FAIL:
            return {
                ...state,
                loading: false
            };
        case GET_TIGERCAT_BURNS_GET_BULK_FUEL_DELIVERY_SUCCESS:
            return {
                ...state,
                loading: false,
                bulkFuelDeliveries: payload
            };
        case GET_TIGERCAT_BURNS_GET_BULK_FUEL_DELIVERY_FAIL:
            return {
                ...state,
                loading: false
            };
        case POST_TIGERCAT_BURNS_POST_BIOCHAR_PRODUCTION_FUEL_USE_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case POST_TIGERCAT_BURNS_POST_BIOCHAR_PRODUCTION_FUEL_USE_FAIL:
            return {
                ...state,
                loading: false
            };
        case GET_TIGERCAT_BURNS_GET_BIOCHAR_PRODUCTION_FUEL_USE_SUCCESS:
            return {
                ...state,
                loading: false,
                biocharProductionFuelUses: payload
            };
        case GET_TIGERCAT_BURNS_GET_BIOCHAR_PRODUCTION_FUEL_USE_FAIL:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
}