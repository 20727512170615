import { Alert, Box, Button, Divider, Grid, Typography, Tab, Tabs } from '@mui/material';
import { useState } from 'react';

export default function FacilitySelection({ setSelection, setFacility }) {

    const group = localStorage.getItem('group')

    const renderList = () => {
        switch (group) {
            case '1': {
                return vinimarkTradingfacilities
            }
            case '2': {
                return ReynekeWinesfacilities
            }
            default: {
                return TweeJongeFacilities
            }
        }
    }

    const vinimarkTradingfacilities = ['Klapmuts', 'Stellenbosch', 'Johannesburg', 'KZN', 'Nelspruit', 'PE']
    const ReynekeWinesfacilities = ['Reyneke Wines Properties', 'Reyneke Wines']
    const TweeJongeFacilities = ['Twee Jonge Gezellen']

    const [currentTab, setCurrentTab] = useState(0)
    const handleTabChange = (newValue) => {
        setCurrentTab(newValue);
    };


    return (
        <Grid item xs={12}>
            <Tabs
                value={currentTab}
                onChange={handleTabChange}
                style={{ marginBottom: 10 }}
                textColor='inherit'
                indicatorColor='secondary'
                className='theTabs'
                sx={{
                    float: 'left',
                    display: 'block'
                }}
            >
                <Tab label='Facilities' />
                <Tab disabled={true} label='General Information' />
            </Tabs>
            {currentTab === 0 ?
                <Box sx={{ clear: 'left' }} >
                    <Typography variant='h5'>Facility</Typography>
                    <Divider />
                    <Alert severity='info' sx={{ mb: 2 }}>Please select a facility</Alert>
                    {renderList().map((f, i) => {
                        return (
                            <Button
                                key={i}
                                variant='contained'
                                size='large'
                                sx={{ width: '50%', display: 'block', mb: 2.5 }}
                                onClick={() => {
                                    let facilityId = ''
                                    if (f === 'Reyneke Wines') {
                                        facilityId = '1'
                                    } else if (f === 'Reyneke Wines Properties') {
                                        facilityId = '2'
                                    } else if (f === 'Twee Jonge Gezellen') {
                                        facilityId = '3'
                                    } else if (f === 'Klapmuts') {
                                        facilityId = '4'
                                    } else if (f === 'Stellenbosch') {
                                        facilityId = '5'
                                    } else if (f === 'Johannesburg') {
                                        facilityId = '6'
                                    } else if (f === 'KZN') {
                                        facilityId = '7'
                                    } else if (f === 'Nelspruit') {
                                        facilityId = '8'
                                    } else if (f === 'PE') {
                                        facilityId = '9'
                                    }
                                    localStorage.setItem('facility', facilityId)
                                    setSelection({
                                        id: 4,
                                        // title: vinimarkTradingfacilities.includes(f) ? 'category' : 'winery'
                                        title: 'category'
                                    })
                                    setFacility(f)
                                }}
                            >
                                {f}
                            </Button>
                        )
                    })
                    }
                </Box> :
                <Box sx={{ clear: 'left' }}>
                    <Typography variant='h5'>Overview</Typography>
                    <Divider />
                </Box>
            }
        </Grid>
    );
}
