import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import { useDispatch, useSelector } from 'react-redux'
import { getAgrochemicalPlotlistData, submitAgrochemicalPlotlistData } from '../../../../../actions/carbonfootprint';
import { CircularProgress } from '@mui/material';

export default function AgrochemicalPlotList() {
    const dispatch = useDispatch()
    const [agrochemicalPlotlistState, setAgrochemicalPlotlistState] = useState([])
    const [agrochemicalAmountAppliedTotals, setAgrochemicalAmountAppliedTotals] = useState([])
    const { loading, agrochemicalPlotlistData } = useSelector(state => state.carbonfootprint) // agrochemicalAmountAppliedTotals

    const facility = localStorage.getItem('facility')
    const year = localStorage.getItem('year')

    useEffect(() => {
        dispatch(getAgrochemicalPlotlistData(facility, year))
    }, [facility, year, dispatch])

    useEffect(() => {
        setAgrochemicalPlotlistState(agrochemicalPlotlistData.plotlistsWithAgrochemicals)
        setAgrochemicalAmountAppliedTotals(agrochemicalPlotlistData.agrochemicalTotals)
    }, [dispatch, agrochemicalPlotlistData])

    const handleApplicationRateChange = (e, plotlistId, agrochemicalPlotlistApplicationId) => {
        setAgrochemicalPlotlistState((prevState) => {
            const newState = prevState.map((apd) => {
                if (apd.plotlist._id === plotlistId) {
                    const newAgrochemicals = apd.agrochemicals.map((agrochemical) => {
                        if (agrochemical.id === agrochemicalPlotlistApplicationId) {
                            return {
                                ...agrochemical,
                                rate: e.target.value,
                                amountApplied: (Number(apd.plotlist.hectares) * Number(e.target.value)).toFixed(2)
                            }
                        }
                        return agrochemical
                    })
                    return {
                        ...apd,
                        agrochemicals: newAgrochemicals
                    }
                }
                return apd
            })
            return newState
        })
    }


    const handleDataSubmission = () => {
        const facility = localStorage.getItem('facility')
        const year = localStorage.getItem('year')
        dispatch(submitAgrochemicalPlotlistData(agrochemicalPlotlistState, facility, year)).then(() => {
            dispatch(getAgrochemicalPlotlistData(facility, year))
        })
    }

    return (
        <Box sx={{ width: '100%', mt: 3 }}>
            <h2>Agrochemical Plotlist Applications</h2>
            <TableContainer component={Paper} sx={{ width: '100%' }} >
                {loading ? <CircularProgress /> :
                    <Table sx={{ minWidth: '100%', width: '100%' }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Block Name</TableCell>
                                <TableCell align="left">Official Land Number</TableCell>
                                <TableCell align="left">Hectares</TableCell>
                                <TableCell align="left">Primary Crop</TableCell>
                                {agrochemicalPlotlistState ? agrochemicalPlotlistState[0]?.agrochemicals?.map((agrochemical) => {
                                    return (
                                        <>
                                            <TableCell align="left">{agrochemical?.productName} <br /><b style={{ color: "darkblue" }}>Application Rate ({agrochemical.applicationRateUnit})</b></TableCell>
                                            <TableCell align="left">Amount Applied </TableCell>
                                        </>
                                    )
                                }) : null}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {agrochemicalPlotlistState?.map((apd) => (
                                <TableRow
                                    key={apd?.plotlist?._id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="left">{apd?.plotlist?.block_name}</TableCell>
                                    <TableCell align="left">{apd?.plotlist?.official_land_num}</TableCell>
                                    <TableCell align="left">{apd?.plotlist?.hectares}</TableCell>
                                    <TableCell align="left">{apd?.plotlist?.primary_crop}</TableCell>
                                    {apd?.agrochemicals?.map((applicationData) => {
                                        return (
                                            <>
                                                <TableCell align="left">
                                                    <TextField
                                                        fullWidth
                                                        name={'application rate'}
                                                        label={''}
                                                        value={!!applicationData.rate ? applicationData.rate : null}
                                                        onChange={(e) => handleApplicationRateChange(e, apd.plotlist._id, applicationData.id)}
                                                    />
                                                </TableCell>
                                                <TableCell align="left">
                                                    {applicationData.amountApplied}
                                                </TableCell>
                                            </>
                                        )
                                    })
                                    }
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>}
            </TableContainer>
            <Button sx={{ m: '1rem 0' }} variant="contained" onClick={handleDataSubmission}>Submit data</Button>
            <h2>Agrochemical Applied</h2>
            <TableContainer component={Paper} sx={{ width: '100%' }} >
                <Table sx={{ minWidth: '100%', width: '100%' }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Agrochemical</TableCell>
                            <TableCell align="left">Application Rate Unit</TableCell>
                            <TableCell align="left">Total Applied</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {agrochemicalAmountAppliedTotals?.map((agrochemical, index) => {
                            return (
                                <TableRow
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="left">{agrochemical.productName}</TableCell>
                                    <TableCell align="left">{agrochemical.applicationRateUnit}</TableCell>
                                    <TableCell align="left">{`${String(agrochemical.totalApplied.toFixed(2))} ${agrochemical.applicationRateUnit === 'kg/ha' ? 'kg' : agrochemical.applicationRateUnit === 'L/ha' ? 'L' : ''}`}</TableCell>
                                </TableRow>
                            )

                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}
