import { useEffect, useState } from 'react';
import { Box, Grid, Typography, IconButton } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import YearSelection from './Levels/YearSelection';
import CompanySelection from './Levels/CompanySelection';
import GroupSelection from './Levels/GroupSelection';
import FacilitySelection from './Levels/FacilitySelection';
import CategorySelection from './Levels/CategorySelection';
import CatOneSelection from './Levels/CatOne';
import WineryCatOneSelection from './Levels/WineryCatOneSelection';
import CatTwoSelection from './Levels/CatTwo';
import CatThreeSelection from './Levels/CatThree';
import CatFourSelection from './Levels/CatFour';
import CatFiveSelection from './Levels/CatFive';
import Diesel from './Categories/CatOne/Diesel';
import Petrol from './Categories/CatOne/Petrol';
import LPG from './Categories/CatOne/LiquidPetroleumGas';
import RefrigerantGas from './Categories/CatOne/RefrigerantGas';
import ElectricityPurchased from './Categories/CatTwo/ElectricityPurchased';
import RenewableEnergyProduced from './Categories/CatTwo/RenewableEnergyProduced';
import BusinessTravel from './Categories/CatThree/BusinessTravel';
import DryIce from './Categories/CatOne/DryIce';
import { getCfUserDetails } from '../../../actions/auth';
import CultivationFertiliser from '../carbonfootprintingrework/Categories/CatOne/AddAssets/CultivationFertiliser'
import CultivationAgroChemicals from '../carbonfootprintingrework/Categories/CatOne/AddAssets/CultivationAgroChemicals';
import CultivationPlotList from '../carbonfootprintingrework/Categories/CatOne/AddAssets/CultivationPlotList';
import AgrochemicalPlotList from './Categories/CatOne/PlotlistTableAgrochemicals';
import FertilizerPlotListTable from './Categories/CatOne/PlotListTableFertilizers';
import AssetList from './Categories/CatOne/AddAssets/AssetList';
import { ConversionTable } from './ConversionTable';
import WasteGeneration from './Categories/CatFive/WasteGeneration';
import PurchasedGoodsAndServices from './Categories/CatFour/PurchasedGoodsAndServices';
import CapitalGoodsAndServices from './Categories/CatFour/CapitalGoodsAndServices';
import SupplierList from './Categories/CatOne/AddAssets/SupplierList';
import PetrolCardHolderList from './Categories/CatOne/AddAssets/PetrolCardHolderList';

export default function CarbonFootprinting() {
    // const { cfUserData } = useSelector(state => state.auth);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCfUserDetails());
    }, [dispatch]);

    // const adminRoles = cfUserData?.admin_roles?.map((e) => e.role.toLowerCase())
    // const dataManagerRoles = cfUserData?.data_manager_roles?.map((e) => e.role.toLowerCase())

    const [selection, setSelection] = useState({ id: 0, title: 'year' });
    const [company, setCompany] = useState('');
    const [group, setGroup] = useState('')
    const [year, setYear] = useState('')
    const [facility, setFacility] = useState('')
    const [category, setCategory] = useState('')
    const [catOne, setCatOne] = useState('')
    const [catTwo, setCatTwo] = useState('')
    const [catThree, setCatThree] = useState('')
    const [catFour, setCatFour] = useState('')
    const [catFive, setCatFive] = useState('')
    const [wineryCatOne, setWineryCategory] = useState('')
    const [correctCategoryTab, setCorrectCategoryTab] = useState(0)

    const RenderComponent = () => {
        switch (selection.title) {
            case 'year':
                return <YearSelection setSelection={setSelection} setYear={setYear} />;
            case 'company':
                return <CompanySelection setSelection={setSelection} setCompany={setCompany} />;
            case 'group':
                return <GroupSelection setSelection={setSelection} setGroup={setGroup} />;
            case 'conversion-factors':
                return <ConversionTable />;
            case 'facility':
                return <FacilitySelection setSelection={setSelection} setFacility={setFacility} />;
            case 'winery':
                return <WineryCatOneSelection setSelection={setSelection} setWineryCategory={setWineryCategory} group={group} />;
            case 'category':
                return <CategorySelection setCorrectTab={correctCategoryTab} setSelection={setSelection} setCategory={setCategory} />;
            case 'catOne':
                return <CatOneSelection setSelection={setSelection} setCatOne={setCatOne} />;
            case 'catTwo':
                return <CatTwoSelection setSelection={setSelection} setCatTwo={setCatTwo} />;
            case 'catThree':
                return <CatThreeSelection setSelection={setSelection} setCatThree={setCatThree} />;
            case 'catFour':
                return <CatFourSelection setSelection={setSelection} setCatFour={setCatFour} />;
            case 'catFive':
                return <CatFiveSelection setSelection={setSelection} setCatFive={setCatFive} />;
            // CAT 1 CATEGORIES
            case 'Diesel':
                return <Diesel setSelection={setSelection} />;
            case 'Petrol':
                return <Petrol setSelection={setSelection} />;
            case 'Liquid petroleum gas (LPG)':
                return <LPG setSelection={setSelection} />;
            case 'Refrigerant gas':
                return <RefrigerantGas setSelection={setSelection} />;
            case 'Dry ice':
                return <DryIce setSelection={setSelection} />;
            // WINERY CATEGORIES
            case 'fertilizerList':
                return <CultivationFertiliser />;
            case 'plotList':
                return <CultivationPlotList />;
            case 'agrochemicalList':
                return <CultivationAgroChemicals />;
            case 'assetList':
                return <AssetList />
            case 'supplierList':
                return <SupplierList />
            case 'petrolCardHolderList':
                return <PetrolCardHolderList />
            case 'Agrochemicals Applied':
                return <AgrochemicalPlotList setSelection={setSelection} />;
            case 'Fertilisers Applied':
                return <FertilizerPlotListTable setSelection={setSelection} />;
            // case 'Compost applied':
            //     return <CompostApplied setSelection={setSelection} />;
            // CAT 2 CATEGORIES
            case 'Electricity purchased':
                return <ElectricityPurchased setSelection={setSelection} />;
            case 'Renewable Energy Produced':
                return <RenewableEnergyProduced setSelection={setSelection} />;
            // CAT 3 CATEGORIES
            case 'Business Travel':
                return <BusinessTravel setSelection={setSelection} />;
            // CAT 4 CATEGORIES
            case 'Capital Goods & Services':
                return <CapitalGoodsAndServices />
            case 'Purchased Goods & Services':
                return <PurchasedGoodsAndServices />
            // CAT 5 CATEGORIES
            case 'Waste Generation':
                return <WasteGeneration />;
            default:
                return <>No selection</>;
        }
    };


    return (
        <>
            <Box sx={{ background: '#535988', borderRadius: 2, p: 2, mb: 2, }}>
                <Typography variant='h5' sx={{ fontWeight: 'bold', color: 'whitesmoke' }}>
                    Carbon Footprinting
                </Typography>
                <Typography variant='h6' sx={{ fontWeight: 'bold', color: 'whitesmoke' }}>
                    {`${!!wineryCatOne || !!catOne || !!catTwo || !!catThree || !!catFour || !!catFive ? `${catOne || catTwo || catThree || catFour || catFive} -` : ''} 
                    ${category ? `${category} -` : ''} 
                    ${facility ? `${facility} -` : ''} 
                    ${group ? `${group} -` : ''} 
                    ${company ? `${company} -` : ''} 
                    ${year}`}
                </Typography>
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <IconButton size='large' onClick={() => {
                        // if COMPANY go back to YEAR
                        if (selection.title === 'company') {
                            setSelection({ id: 0, title: 'year' })
                            setCompany('')
                        }
                        // if GROUP go back to COMPANY
                        if (selection.title === 'group') {
                            setCompany('')
                            setGroup('')
                            setSelection({ id: 1, title: 'company' })
                        }
                        // if FACILITY go back to GROUP
                        if (selection.title === 'facility' || selection.title === 'conversion-factors') {
                            setGroup('')
                            setFacility('')
                            setSelection({ id: 2, title: 'group' })
                        }
                        // if CATEGORY go back to FACILITY
                        if (selection.title === 'category') {
                            setFacility('')
                            setCategory('')
                            setSelection({ id: 3, title: 'facility' })
                        }
                        if (['plotList', 'fertilizerList', 'agrochemicalList', 'assetList', 'supplierList', 'petrolCardHolderList'].includes(selection.title)) {
                            setCorrectCategoryTab(1)
                            setSelection({ id: 4, title: 'category' })
                        }
                        if (selection.title === 'category' && facility.includes(['Klapmuts', 'Stellenbosch', 'Johannesburg'])) {
                            setFacility('')
                            setSelection({ id: 5, title: 'facility' })
                        }
                        // IF WE'RE IN ONE OF THE FIVE CATEGORIES WE WANT TO GO BACK TO THE LIST OF ALL OF THEM
                        if ([
                            'catOne',
                            'catTwo',
                            'catThree',
                            'catFour',
                            'catFive'].includes(selection.title)) {
                            setCategory('');
                            setCatOne('');
                            setCatTwo('');
                            setCatThree('');
                            setCatFour('');
                            setCatFive('')
                            setSelection({ id: 6, title: 'category' })
                            setCorrectCategoryTab(0)

                        }
                        // IF WE'RE IN CAT ONE, WE WANT TO GO TO THE catOne CATEGORIES
                        if ([
                            'Diesel',
                            'Petrol',
                            'Petrol - Stationary',
                            'Petrol - Mobile',
                            'Dry ice',
                            'Liquid petroleum gas (LPG)',
                            'Refrigerant gas',
                            'Fertilisers Applied',
                            'Agrochemicals Applied'
                        ].includes(selection.title)) {
                            setSelection({ id: 7, title: 'catOne' })
                        }
                        // IF WE'RE IN CAT TWO, WE WANT TO GO TO THE catTwo CATEGORIES
                        if ([
                            'Electricity purchased',
                            'Renewable Energy Produced',
                        ].includes(selection.title)) {
                            setSelection({ id: 8, title: 'catTwo' })
                        }
                        // IF WE'RE IN CAT THREE, WE WANT TO GO TO THE catThree CATEGORIES
                        if ([
                            'Business Travel',
                            'Downstream Distribution',
                            'Freighting goods',
                            'Upstream Transportation and Distribution',
                            'Fuel & energy related emissions not included in scope 1 and 2',
                            'Employee commuting'
                        ].includes(selection.title)) {
                            setSelection({ id: 9, title: 'catThree' })
                        }
                        // IF WE'RE IN CAT FOUR, WE WANT TO GO TO THE catFour CATEGORIES
                        if ([
                            'Purchased Goods & Services',
                            'Capital Goods & Services'
                        ].includes(selection.title)) {
                            setSelection({ id: 10, title: 'catFour' })
                        }
                        // IF WE'RE IN CAT FIVE, WE WANT TO GO TO THE catFive CATEGORIES
                        if ([
                            'Waste Generation',
                        ].includes(selection.title)) {
                            setSelection({ id: 11, title: 'catFive' })
                        }
                    }}>
                        <ArrowBack />
                    </IconButton>
                </Grid>
            </Grid >
            <Grid container spacing={2}>
                {RenderComponent()}
            </Grid>
        </>
    );
}
