import React, { useEffect } from 'react';
import AddItem from '../../../../carbonfootprinting/ItemAdd';
import {
    Box,
    Table, TableBody, TableContainer, TableRow, TableHead, TableCell, Paper
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { addSupplier, getSuppliers } from '../../../../../../actions/carbonfootprint-rework/04-indirect-ghg-emissions-from-products-used-by-an-organisation/00-suppliers';

export default function SupplierList() {
    // { farmId }
    const dispatch = useDispatch()
    const { suppliers } = useSelector(state => state.indirectGhgEmissionsFromProductsSuppliers)
    const facility = localStorage.getItem('facility')
    const year = localStorage.getItem('year')
    const group = localStorage.getItem('group')
    const company = localStorage.getItem('company')

    useEffect(() => {
        dispatch(getSuppliers(facility))
    }, [dispatch, facility])

    const formFields = [
        { name: 'name', label: 'Supplier Name', type: 'text' },
        { name: 'address', label: 'Supplier Address', type: 'text' }
    ];

    const initialState = formFields.reduce((obj, field) => {
        obj[field.name] = '';
        return obj;
    }, {});


    const handleFormSubmit = data => {
        dispatch(addSupplier(data, year, company, group, facility)).then(() => {
            dispatch(getSuppliers(facility))
        })
    };

    const columns = [
        {
            field: 'name', headerName: 'Supplier Name',
            flex: 1,

        },
        {
            field: 'address', headerName: 'Address',
            flex: 1,
        },
        {
            field: 'created_at', headerName: 'Date Created',
            flex: 1
        }
    ]

    return (
        <Box sx={{ width: '100%' }} mt={5}>
            <Box sx={{ height: '100%' }}>
                <AddItem
                    formFields={formFields}
                    initialState={initialState}
                    onFormSubmit={handleFormSubmit}
                    items={[]}
                    addTitle='Add New Supplier'
                />
                <TableContainer component={Paper} sx={{ height: 'auto' }}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {columns.map((c, i) => {
                                    return (

                                        <TableCell key={i} align="left">
                                            {c.headerName}
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {suppliers?.data?.map((d, i) => {
                                return (
                                    <TableRow key={i}>
                                        <TableCell align="left">
                                            {d.name}
                                        </TableCell>
                                        <TableCell align="left">
                                            {d.address}
                                        </TableCell>
                                        <TableCell align="left">
                                            {new Date(d.createdAt).toISOString().split('T')[0]}
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
}
