import React from 'react'
import { Box, Button, Divider, Grid, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCompanyCarRppb, createUserCarRppbProject, getCarRppbProjects, getCompanyUserCarRppbs } from '../../../actions/tigercat-burns/tigercat-base-actions';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';

import AddCarRppbDialog from './dialogs/AddCarRppbDialog';
import AddProjectDialog from './dialogs/AddProjectDialog';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import BookmarkOutlinedIcon from '@mui/icons-material/BookmarkOutlined';
import BiocharData from './main-items/BiocharData';
import BulkFuelDelivery from './main-items/Fuel/BulkFuelDelivery';
import BiocharProductionFuel from './main-items/Fuel/BiocharProductionFuel';

const CarRppb = () => {
    const dispatch = useDispatch()
    const [activeCarRppb, setActiveCarRppb] = useState(null);
    const [activeCarRppbProject, setActiveCarRppbProject] = useState(null);

    // Car Rppb modal    
    const [openCarRppbModal, setOpenCarRppbModal] = useState(false);
    const [reportingEntityName, setReportingEntityName] = useState('')
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)

    // Project modal
    const [openProjectModal, setOpenProjectModal] = useState(false);
    const [projectName, setProjectName] = useState('')

    // Add bin to project modal
    // const [openAddBinToProjectModal, setOpenAddBinToProjectModal] = useState(false);

    // Add equipment to project modal
    // const [openAddEquipmentToProjectModal, setOpenAddEquipmentToProjectModal] = useState(false);


    const [hide, setHide] = useState(true);
    const [hideProjects, setHideProjects] = useState(true);


    // Main menu items
    const [activeStep, setActiveStep] = useState(null)
    const [activeSubItem, setActiveSubItem] = useState('')

    const carRppbs = useSelector(state => state.tigercatBurnsBaseReducer.carRppbs.data)
    const carRppbProjects = useSelector(state => state.tigercatBurnsBaseReducer.carRppbProjects.data)
    const { userData } = useSelector(state => state.auth);
    const userCompanyId = userData?.tigercatBurnsUser?.companyId;

    const handleSetActiveCarRppb = (carRppb) => {
        setActiveCarRppb(carRppb)
        dispatch(getCarRppbProjects(carRppb.id))
    }

    useEffect(() => {
        if (userCompanyId)
            dispatch(getCompanyUserCarRppbs(userCompanyId))
    }, [dispatch, userCompanyId])

    const handleSubmitCarRppb = () => {
        setOpenCarRppbModal(false)
        dispatch(createCompanyCarRppb({ reportingEntityName, startDate, endDate }, userCompanyId)).then(() => {
            dispatch(getCompanyUserCarRppbs(userCompanyId))
            setStartDate(null)
            setEndDate(null)
            setReportingEntityName('')
        })
    }

    const handleSubmitProject = () => {
        setOpenProjectModal(false)
        dispatch(createUserCarRppbProject(activeCarRppb.id, { projectName })).then(() => {
            dispatch(getCarRppbProjects(activeCarRppb.id))
            setProjectName('')
        })
    }

    const items = [
        { id: 0, name: 'Biochar Production', subItems: ['Biochar Data', 'Equipment Transport'] },
        { id: 1, name: 'Fuel', subItems: ['Bulk Fuel Delivery', 'Biochar Production Fuel', 'Feedstock Processing Fuel'] },
        { id: 2, name: 'Feedstock' },
        { id: 3, name: 'End Use' },
        { id: 4, name: 'Factors & Sampling' },
        { id: 5, name: 'Project Info' }
    ]

    return (
        <Grid>
            <Box sx={{ background: '#535988', borderRadius: 2, p: 2, mb: 2, }}>
                <Typography variant='h5' sx={{ fontWeight: 'bold', color: 'whitesmoke' }}>
                    Car Reporting Period Project Bundles
                </Typography>
            </Box>
            <Divider sx={{ my: 2 }} />
            <Accordion defaultExpanded>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    onClick={() => setHide(!hide)}
                >
                    <Typography>{hide ? "Hide" : "Expand"} CAR Rppbs</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Button
                        variant='outlined'
                        size='medium'
                        sx={{ mt: 3, mb: 3, height: '3.4em', width: '15.5rem' }}
                        onClick={() => setOpenCarRppbModal(true)}
                    ><AddIcon></AddIcon>Add New CAR Rppb</Button>
                    <Grid xs={12} display="flex" alignContent={'space-between'} flexWrap={'wrap'} mb={'1rem'}>
                        {carRppbs?.map((carRppb, index) => {
                            return (
                                <Button
                                    sx={{ mr: 2 }}
                                    key={index}
                                    variant={carRppb.id === activeCarRppb?.id ? 'contained' : 'outlined'}
                                    color='primary'
                                    onClick={() => handleSetActiveCarRppb(carRppb)}>
                                    {carRppb.reportingEntityName}
                                </Button>
                            )
                        })}
                    </Grid>
                </AccordionDetails>
            </Accordion>
            {
                activeCarRppb &&
                <Accordion defaultExpanded>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        onClick={() => setHideProjects(!hideProjects)}
                    >
                        <Typography>{hideProjects ? "Hide" : "Expand"} Projects</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Button
                            variant='outlined'
                            size='medium'
                            sx={{ mt: 3, mb: 3, height: '3.4em', width: '15.5rem' }}
                            onClick={() => setOpenProjectModal(true)}
                        ><AddIcon></AddIcon>Add New Project</Button>
                        <Grid container>
                            <Typography variant='h5' component='h4' sx={{ mb: 3 }}>
                                CAR Rppb Projects
                            </Typography>
                            <Grid xs={12} display="flex" alignContent={'space-between'} flexWrap={'wrap'} mb={'1rem'}>
                                {carRppbProjects?.map((project, index) => {
                                    return (

                                        <Box className="biochar-project" key={index}
                                            sx={activeCarRppbProject?.id === project.id && { background: 'linear-gradient(to bottom right, #535988, #313551)', color: '#FFF', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px' }}
                                            onClick={() => {
                                                setActiveCarRppbProject(project)
                                                // setProjectId(project.projectId);
                                                // setNickname(project.nickname);
                                                // setProjectKey(project._id)
                                                // setProjectMeasureType(project.measurement_type)
                                            }}>
                                            <Typography sx={{ fontSize: '18px' }}>{activeCarRppbProject?.id === project.id ? <BookmarkOutlinedIcon sx={{ mb: '-7px' }} /> : <BookmarkBorderOutlinedIcon sx={{ mb: '-7px' }} />} {project.id}</Typography>
                                            <Typography sx={project.projectName ? { fontSize: '14px' } : { visibility: 'hidden' }} mt={3}><strong>Project Name:</strong> {project.projectName}</Typography>
                                            <Typography sx={{ fontSize: '14px' }}><strong>Date Created:</strong> {project.createdAt.split('T')[0]}</Typography>
                                        </Box>
                                    )
                                })}
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            }
            {activeCarRppbProject &&
                <>
                    <Divider sx={{ my: 2 }} />
                    <Grid container sx={{ justifyContent: 'space-between' }}>
                        <Grid item xs={6}>
                            <Typography variant='h5' component='h5' sx={{ mb: 3 }}>
                                <strong>Selected Project:</strong> {activeCarRppbProject?.projectName}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider sx={{ my: 2 }} />
                    <Grid container>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                            {items.map((item, i) => {
                                return (
                                    <Grid item sx={{ display: 'flex' }} key={i}>
                                        <Button
                                            variant={activeStep === item.id ? 'contained' : 'outlined'}
                                            onClick={() => { setActiveStep(item.id); setActiveSubItem('') }}
                                            disabled={[2, 3, 4, 5].includes(item.id) ? true : false}
                                        >
                                            {item.name}
                                        </Button>
                                    </Grid>
                                    //         <Tooltip key={i} title={<h2 style={{ color: 'lightgreen' }}>{item.name}</h2>}>
                                    // </Tooltip>
                                )
                            })}
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', mb: 2 }}>
                            {activeStep === 0 && items[0].subItems.map((item, i) => {
                                return (
                                    <Grid item sx={{ display: 'flex' }} key={i}>
                                        <Button
                                            sx={{ mr: 3 }}
                                            variant={activeSubItem === item ? 'contained' : 'outlined'}
                                            onClick={() => setActiveSubItem(item)}
                                            disabled={i == 1}
                                        >
                                            {item}
                                        </Button>
                                    </Grid>
                                    //         <Tooltip key={i} title={<h2 style={{ color: 'lightgreen' }}>{item.name}</h2>}>
                                    // </Tooltip>
                                )
                            })}
                            {activeStep === 1 && items[1].subItems.map((item, i) => {
                                return (
                                    <Grid item sx={{ display: 'flex' }} key={i}>
                                        <Button
                                            sx={{ mr: 3 }}
                                            variant={activeSubItem === item ? 'contained' : 'outlined'}
                                            onClick={() => setActiveSubItem(item)}
                                        >
                                            {item}
                                        </Button>
                                    </Grid>
                                    //         <Tooltip key={i} title={<h2 style={{ color: 'lightgreen' }}>{item.name}</h2>}>
                                    // </Tooltip>
                                )
                            })}
                        </Grid>
                        <Grid xs={12}>
                            <Divider sx={{ my: 2 }} />
                            {activeStep === 0 && activeSubItem === 'Biochar Data' ?
                                <BiocharData projectId={activeCarRppbProject?.id} /> : null
                            }
                            {activeStep === 1 && activeSubItem === 'Bulk Fuel Delivery' ?
                                <BulkFuelDelivery projectId={activeCarRppbProject?.id} /> : null
                            }
                            {activeStep === 1 && activeSubItem === 'Biochar Production Fuel' ?
                                <BiocharProductionFuel projectId={activeCarRppbProject?.id} /> : null
                            }
                            {activeStep === 1 && activeSubItem === 'Feedstock Processing Fuel' ?
                                <BiocharProductionFuel projectId={activeCarRppbProject?.id} /> : null
                            }
                        </Grid>

                    </Grid>
                </>
            }
            <AddCarRppbDialog
                openCarRppbModal={openCarRppbModal}
                setOpenCarRppbModal={setOpenCarRppbModal}
                reportingEntityName={reportingEntityName}
                setReportingEntityName={setReportingEntityName}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                handleSubmitCarRppb={handleSubmitCarRppb}
            />
            <AddProjectDialog
                openProjectModal={openProjectModal}
                setOpenProjectModal={setOpenProjectModal}
                projectName={projectName}
                setProjectName={setProjectName}
                handleSubmitProject={handleSubmitProject}
            />
        </Grid >
    )
}

export default CarRppb