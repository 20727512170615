import { Alert, Box, Button, Divider, Grid, Typography, Tab, Tabs } from '@mui/material';
import { useEffect, useState } from 'react';

export default function CategorySelection({ setSelection, setCategory, setCorrectTab }) {
    const categories = [
        { cat: 'catOne', name: 'Fuel, Dry Ice, Refrigerant, Fertilisers & Agrochemicals' },
        { cat: 'catTwo', name: 'Electricity' },
        { cat: 'catThree', name: 'Business Travel & Downstream Distribution' },
        { cat: 'catFour', name: 'Purchased Goods & Services, Capital Goods & Services' },
        { cat: 'catFive', name: 'Waste Generation' },
    ]

    let dataInputCategories = [
        { cat: 'assetList', name: 'Asset List' },
        { cat: 'supplierList', name: 'Supplier List' },
        { cat: 'petrolCardHolderList', name: 'Petrol Card Holder List' },
        { cat: 'plotList', name: 'Plot List' },
        { cat: 'fertilizerList', name: 'Fertiliser List' },
        { cat: 'agrochemicalList', name: 'Agrochemical List' },
    ]

    if (localStorage.getItem('group') === '1') {
        dataInputCategories = dataInputCategories.splice(0, 3)
    }

    const [currentTab, setCurrentTab] = useState(0)
    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    useEffect(() => {
        if (setCorrectTab === 0) {
            setCurrentTab(0)
        } else setCurrentTab(1)
    }, [setCorrectTab])


    return (
        <Grid item xs={12}>
            <Tabs
                value={currentTab}
                onChange={handleTabChange}
                style={{ marginBottom: 10 }}
                textColor='inherit'
                indicatorColor='secondary'
                className='theTabs'
                sx={{
                    float: 'left',
                    display: 'block'
                }}
            >
                <Tab label='Data Capturing' />
                <Tab label='Data Input' />
            </Tabs>
            {currentTab === 0 ?
                <Box marginTop={2} sx={{ clear: 'left' }}>
                    <Typography variant='h5'>Categories</Typography>
                    <Divider />
                    <Alert severity='info' sx={{ mb: 2 }}>Please select a data capturing category</Alert>
                    {categories.map((c, i) => {
                        return (
                            <Button
                                key={i}
                                variant='contained'
                                size='large'
                                sx={{ width: '35%', display: 'block', mb: 2.5 }}
                                onClick={() => {
                                    setCategory(c.name)
                                    setSelection({ id: 4, title: c.cat })
                                    localStorage.setItem('category', c.cat)
                                }}
                            >
                                {c.name}
                            </Button>
                        )
                    })
                    }
                </Box>
                :
                <Box sx={{ clear: 'left' }}>
                    <Typography variant='h5'>Data Input</Typography>
                    <Divider />
                    <Alert severity='info' sx={{ mb: 2 }}>Please select a data input category</Alert>
                    {dataInputCategories.map((c, i) => {
                        return (
                            <Button
                                key={i}
                                variant='contained'
                                size='large'
                                sx={{ width: '35%', display: 'block', mb: 2.5 }}
                                onClick={() => {
                                    setCategory(c.name)
                                    setSelection({ id: 4, title: c.cat })
                                    // localStorage.setItem('category', c.cat)
                                }}
                            >
                                {c.name}
                            </Button>
                        )
                    })
                    }
                </Box>}
        </Grid>
    );
}
