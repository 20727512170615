export const GET_TIGERCAT_BURNS_BIOCHAR_VOLUMES_SUCCESS = 'GET_TIGERCAT_BURNS_BIOCHAR_VOLUMES_SUCCESS';
export const GET_TIGERCAT_BURNS_BIOCHAR_VOLUMES_FAIL = 'GET_TIGERCAT_BURNS_BIOCHAR_VOLUMES_FAIL';

export const POST_TIGERCAT_BURNS_POST_BULK_FUEL_DELIVERY_SUCCESS = 'POST_TIGERCAT_BURNS_POST_BULK_FUEL_DELIVERY_SUCCESS';
export const POST_TIGERCAT_BURNS_POST_BULK_FUEL_DELIVERY_FAIL = 'POST_TIGERCAT_BURNS_POST_BULK_FUEL_DELIVERY_FAIL';

export const GET_TIGERCAT_BURNS_GET_BULK_FUEL_DELIVERY_SUCCESS = 'GET_TIGERCAT_BURNS_GET_BULK_FUEL_DELIVERY_SUCCESS';
export const GET_TIGERCAT_BURNS_GET_BULK_FUEL_DELIVERY_FAIL = 'GET_TIGERCAT_BURNS_GET_BULK_FUEL_DELIVERY_FAIL';

export const POST_TIGERCAT_BURNS_POST_BIOCHAR_PRODUCTION_FUEL_USE_SUCCESS = 'POST_TIGERCAT_BURNS_POST_BIOCHAR_PRODUCTION_FUEL_USE_SUCCESS';
export const POST_TIGERCAT_BURNS_POST_BIOCHAR_PRODUCTION_FUEL_USE_FAIL = 'POST_TIGERCAT_BURNS_POST_BIOCHAR_PRODUCTION_FUEL_USE_FAIL';

export const GET_TIGERCAT_BURNS_GET_BIOCHAR_PRODUCTION_FUEL_USE_SUCCESS = 'GET_TIGERCAT_BURNS_GET_BIOCHAR_PRODUCTION_FUEL_USE_SUCCESS';
export const GET_TIGERCAT_BURNS_GET_BIOCHAR_PRODUCTION_FUEL_USE_FAIL = 'GET_TIGERCAT_BURNS_GET_BIOCHAR_PRODUCTION_FUEL_USE_FAIL';