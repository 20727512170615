import React from 'react'
import {
    Box, Table, TableBody, TableContainer, TableRow, TableHead, Paper, TableCell
} from '@mui/material';

export default function PetrolCardTotalTable({ petrolCardDataTotals }) {

    return (
        <Box>
            <TableContainer component={Paper} sx={{ height: 'auto' }}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">
                                Month
                            </TableCell>
                            {/* <TableCell align="left">
                                Kilometers Value
                            </TableCell> */}
                            {/* <TableCell align="left">
                                Total Kilometers
                            </TableCell> */}
                            {/* <TableCell align="left">
                                Litres Value
                            </TableCell> */}
                            <TableCell align="left">
                                Total Litres
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {petrolCardDataTotals?.map((data, i) => {

                            if (!Object.keys(data).includes('kilometerGrandTotal')) {
                                return (
                                    <TableRow key={i}>
                                        <TableCell align="left">
                                            {data.month}
                                        </TableCell>
                                        {/* <TableCell align="left">
                                            {data.kilometerQuantity}
                                        </TableCell> */}
                                        {/* <TableCell align="left">
                                            {data.kilometerTotal}
                                        </TableCell> */}
                                        {/* <TableCell align="left">
                                            {data.litresQuantity}
                                        </TableCell> */}
                                        <TableCell align="left">
                                            {data.litresTotal}
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                        })}
                        <TableRow sx={{ border: '2px solid black' }}>
                            <TableCell align="left"><b>Grand Total</b></TableCell>
                            {/* <TableCell align="left"><b>{petrolCardDataTotals[12].kilometerGrandTotal}</b></TableCell> */}
                            <TableCell align="left"><b>{petrolCardDataTotals[12].litreGrandTotal}</b></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}
