import { connect } from 'react-redux';
import { getBatchData } from '../../../../../../actions/forms';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import {
    Table, TableBody, TableContainer, TableRow, TableHead, Paper, TableCell, CircularProgress, Alert, Accordion, AccordionSummary, AccordionDetails, Typography
} from '@mui/material';
import { getCbAdditionalBatchData, getCbBatchData } from '../../../../../../actions/conservation-burns/conservation-burn-actions';
import { getRofBurnsAdditionalBatchData, getRofBurnsBatchData } from '../../../../../../actions/rof-burns/rof-actions';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const RofBatchData = ({ projectKey, cbBatchData, cbAdditionalBatchData, rofBatchData, rofAdditionalBatchData, measureType }) => {
    const dispatch = useDispatch();
    const [cbBatchDataState, setCbBatchDataState] = useState({});
    const [rofBurnsBatchDataState, setRofBurnsBatchDataState] = useState({});
    const [loading, setLoading] = useState(true);
    const [hideState, setHideState] = useState({})

    const toggleHide = (batchId) => {
        setHideState(prevState => ({
            ...prevState,
            [batchId]: !prevState[batchId]
        }))
    }

    useEffect(() => {
        dispatch(getRofBurnsBatchData(projectKey));
        dispatch(getRofBurnsAdditionalBatchData(projectKey));
        dispatch(getCbBatchData(projectKey));
        dispatch(getCbAdditionalBatchData(projectKey));
        setLoading(false);
    }, [dispatch, projectKey]);

    useEffect(() => {
        // Step 1: Create a mapping of the second array
        if (cbBatchData?.data && cbAdditionalBatchData?.data) {
            const secondArrayMap = cbAdditionalBatchData?.data?.reduce((acc, item) => {
                acc[item.batchId] = item;
                return acc;
            }, {});

            // Step 2: Iterate over the first array and merge the corresponding objects
            const mergedArray = cbBatchData?.data?.map(item => {
                const additionalData = secondArrayMap[item.batchId];
                return {
                    ...item,
                    additionalBatchData: additionalData
                };
            });

            // Step 3: Construct the final object
            const finalObject = mergedArray?.reduce((acc, item) => {
                if (!acc[item.batchId]) {
                    acc[item.batchId] = [];
                }
                acc[item.batchId].push(item);
                return acc;
            }, {});

            setCbBatchDataState({ ...finalObject });
        }
    }, [cbBatchData, cbAdditionalBatchData]);

    useEffect(() => {
        if (rofBatchData?.data && rofAdditionalBatchData?.data) {
            // RofBatchData
            const additionalBatchDataMap = rofAdditionalBatchData?.data?.reduce((acc, item) => {
                acc[item?.batchId] = item;
                return acc;
            }, {});

            // Step 2: Iterate over the first array and merge the corresponding objects
            const rofMergedArray = rofBatchData?.data?.map(item => {
                const additionalData = additionalBatchDataMap[item?.batchId];
                return {
                    ...item,
                    additionalBatchData: additionalData
                };
            });

            // Step 3: Construct the final object
            const rofFinalObject = rofMergedArray?.reduce((acc, item) => {
                if (!acc[item.batchId]) {
                    acc[item.batchId] = [];
                }
                acc[item.batchId].push(item);
                return acc;
            }, {});

            setRofBurnsBatchDataState({ ...rofFinalObject });
        }
    }, [rofAdditionalBatchData, rofBatchData]);

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <>
            <h2>Ring of Fire Batch Data</h2>
            {!rofBurnsBatchDataState &&
                <Alert severity="info">No Ring of Fire currenly in this project</Alert>}
            {cbBatchDataState && Object.keys(cbBatchDataState)?.map((key, index) => {
                if (!rofBurnsBatchDataState[key]) {
                    return (
                        <Alert key={index} severity="info" sx={{ my: 3 }}>No Conservation Burn Batch Data for Batch: {key}</Alert>
                    )
                }
            })}
            {
                rofBurnsBatchDataState && Object.keys(rofBurnsBatchDataState)?.map((key, index) => {
                    return (
                        <Accordion key={index}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                onClick={() => toggleHide(`ROF - ${key}`)}
                            >
                                <Typography>{hideState[`ROF - ${key}`] ? "Hide" : "Expand"} {key}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <h3>Batch: {key}</h3>
                                <TableContainer component={Paper} sx={{ height: 'auto' }}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="left">
                                                    Kiln Id
                                                </TableCell>
                                                <TableCell align="left">
                                                    Gross Volume
                                                </TableCell>
                                                <TableCell align="left">
                                                    Unburnt Volume
                                                </TableCell>
                                                <TableCell align="left">
                                                    Net Volume
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rofBurnsBatchDataState[key]?.map((bd, index) => {
                                                return (
                                                    <TableRow key={index}>
                                                        <TableCell align="left">
                                                            {bd?.kilnUsed}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {bd?.grossVol ? <>{bd.grossVol}{measureType === 'Imperial' ? <> ft<sup>3</sup></> : <> m<sup>3</sup></>}</> : ""}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {bd?.partiallyBurntVol ? <>{bd.partiallyBurntVol}{measureType === 'Imperial' ? <> ft<sup>3</sup></> : <> m<sup>3</sup></>}</> : 0}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {bd?.netVol ? <>{bd.netVol}{measureType === 'Imperial' ? <> ft<sup>3</sup></> : <> m<sup>3</sup></>}</> : ""}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <h4>Additional Batch Data:</h4>
                                <TableContainer component={Paper} sx={{ height: 'auto', mt: '2em' }}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="left">
                                                    Kiln Id
                                                </TableCell>
                                                <TableCell align="left">
                                                    Batch Location
                                                </TableCell>
                                                <TableCell align="left">
                                                    Feedstock Type
                                                </TableCell>
                                                <TableCell align="left">
                                                    Feedstock Moisture
                                                </TableCell>
                                                <TableCell align="left">
                                                    Empty Bucket Mass
                                                </TableCell>
                                                <TableCell align="left">
                                                    Bucket Volume
                                                </TableCell>
                                                <TableCell align="left">
                                                    Hot Char & Bucket Weight
                                                </TableCell>
                                                <TableCell align="left">
                                                    Burn Start Timestamp
                                                </TableCell>
                                                <TableCell align="left">
                                                    Burn Supervisor
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rofBurnsBatchDataState[key]?.map((bd, index) => {
                                                return (
                                                    <TableRow key={index}>
                                                        <TableCell align="left">
                                                            {bd?.kilnUsed}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {bd?.additionalBatchData?.latitude ? <>{bd.additionalBatchData.latitude}/{bd.additionalBatchData.longitude}</> : ""}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {bd?.additionalBatchData?.feedstockType ? <>{bd.additionalBatchData.feedstockType}</> : ""}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {bd?.additionalBatchData?.feedstockMoisture ? <>{bd.additionalBatchData.feedstockMoisture}</> : ""}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {bd?.additionalBatchData?.emptyBucketMass ? <>{bd.additionalBatchData.emptyBucketMass}</> : null}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {bd?.additionalBatchData?.bucketVolume ? <>{bd.additionalBatchData.bucketVolume}</> : null}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {bd?.additionalBatchData?.hotCharAndBucketMass ? <>{bd.additionalBatchData.hotCharAndBucketMass}</> : null}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {bd?.additionalBatchData?.burnStartTimestamp ? <>{new Date(bd.additionalBatchData.burnStartTimestamp).toISOString()}</> : null}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {bd?.additionalBatchData?.supervisorName ? <>{bd.additionalBatchData.supervisorName}</> : null}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </AccordionDetails>
                        </Accordion>
                    )
                })}
            <h2>Conservation Burns Batch Data</h2>
            {!cbBatchDataState && <Alert severity="info" sx={{ my: 3 }}>No Conservation Burns currently in this project</Alert>}
            {/* {rofBurnsBatchDataState && Object.keys(rofBurnsBatchDataState)?.map((key, index) => {
                if (!cbBatchDataState[key]) {
                    return (
                        <Alert key={index} severity="info" sx={{ my: 3 }}>No Conservation Burn Batch Data for Batch: {key}</Alert>
                    )
                }
            })} */}
            {
                cbBatchDataState && Object.keys(cbBatchDataState)?.map((key, index) => {
                    return (
                        <Accordion key={index}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                onClick={() => toggleHide(`CB - ${key}`)}
                            >
                                <Typography>{hideState[`CB - ${key}`] ? "Hide" : "Expand"} {key}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <h3>Batch: {key}</h3>
                                <TableContainer component={Paper} sx={{ height: 'auto' }}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="left">
                                                    CB Id
                                                </TableCell>
                                                <TableCell align="left">
                                                    Bulk Density
                                                </TableCell>
                                                <TableCell align="left">
                                                    Bucket Volumes
                                                </TableCell>
                                                <TableCell align="left">
                                                    Partially Burnt Volume
                                                </TableCell>
                                                <TableCell align="left">
                                                    Biochar Pile Count
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {cbBatchDataState[key].map((bd, index) => {
                                                return (
                                                    <TableRow key={index}>
                                                        <TableCell align="left">
                                                            {bd?.cbId}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {bd?.bulkDensities}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {bd?.bucketVolume}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {bd?.partiallyBurntVolume}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {bd?.biocharPileCount}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <h4>Additional Batch Data:</h4>
                                <TableContainer component={Paper} sx={{ height: 'auto', mt: 2 }}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="left">
                                                    Pile Diameter
                                                </TableCell>
                                                <TableCell align="left">
                                                    Pile Height
                                                </TableCell>
                                                <TableCell align="left">
                                                    Feedstock Type
                                                </TableCell>
                                                <TableCell align="left">
                                                    Feedstock Pile Count
                                                </TableCell>
                                                <TableCell align="left">
                                                    Moisture Content
                                                </TableCell>
                                                <TableCell align="left">
                                                    Date
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {cbBatchDataState[key].map((bd, index) => {
                                                return (
                                                    <TableRow key={index}>
                                                        <TableCell align="left">
                                                            {bd?.additionalBatchData?.pileDiam}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {bd?.additionalBatchData?.pileHeight}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {bd?.additionalBatchData?.feedstockType}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {bd?.additionalBatchData?.pileCount}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {bd?.additionalBatchData?.moistureMeasurement}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {new Date(bd?.additionalBatchData?.createdAt).toISOString()}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </AccordionDetails>
                        </Accordion>
                    )
                })
            }

        </>
    )
}

const mapStateToProps = state => ({
    rofBatchData: state.rofBurnsReducer.batchData,
    rofAdditionalBatchData: state.rofBurnsReducer.additionalBatchData,
    cbBatchData: state.conservationBurnsReducer.batchData,
    cbAdditionalBatchData: state.conservationBurnsReducer.additionalBatchData,
    loading: state.postFormData.loading,
});

const mapDispatchToProps = dispatch => {
    return {
        getBatchData: projectKey => dispatch(getBatchData(projectKey))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RofBatchData);