import { setAlert } from '../alert';
import {
    API_GET_TIGERCAT_BURNS_GET_BIOCHAR_VOLUMES,
    API_POST_TIGERCAT_BURNS_POST_BULK_FUEL_DELIVERY,
    API_GET_TIGERCAT_BURNS_GET_BULK_FUEL_DELIVERY,
    API_POST_TIGERCAT_BURNS_POST_BIOCHAR_PRODUCTION_FUEL_USE,
    API_GET_TIGERCAT_BURNS_GET_BIOCHAR_PRODUCTION_FUEL_USE
} from './constants/tigercat-constants';

import {
    GET_TIGERCAT_BURNS_BIOCHAR_VOLUMES_SUCCESS,
    GET_TIGERCAT_BURNS_BIOCHAR_VOLUMES_FAIL,
    //
    POST_TIGERCAT_BURNS_POST_BULK_FUEL_DELIVERY_SUCCESS,
    POST_TIGERCAT_BURNS_POST_BULK_FUEL_DELIVERY_FAIL,
    //
    GET_TIGERCAT_BURNS_GET_BULK_FUEL_DELIVERY_SUCCESS,
    GET_TIGERCAT_BURNS_GET_BULK_FUEL_DELIVERY_FAIL,
    //
    POST_TIGERCAT_BURNS_POST_BIOCHAR_PRODUCTION_FUEL_USE_SUCCESS,
    POST_TIGERCAT_BURNS_POST_BIOCHAR_PRODUCTION_FUEL_USE_FAIL,
    //
    GET_TIGERCAT_BURNS_GET_BIOCHAR_PRODUCTION_FUEL_USE_SUCCESS,
    GET_TIGERCAT_BURNS_GET_BIOCHAR_PRODUCTION_FUEL_USE_FAIL
} from './types/tigercat-types';

import axios from 'axios';
const token = JSON.parse(localStorage.getItem('token'))?.token;

const config = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
    },
};

const formDataConfig = {
    headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
    }
}

export const getBiocharVolumes = (carRppbProjectId) => async dispatch => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + API_GET_TIGERCAT_BURNS_GET_BIOCHAR_VOLUMES + `/${carRppbProjectId}`,
            config
        );
        dispatch({
            type: GET_TIGERCAT_BURNS_BIOCHAR_VOLUMES_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: GET_TIGERCAT_BURNS_BIOCHAR_VOLUMES_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const postBulkFuelDelivery = (carRppbProjectId, formDataArray) => async dispatch => {
    try {
        const formData = new FormData();
        formDataArray.forEach((formDataItem, index) => {
            for (const [key, value] of formDataItem.entries()) {
                formData.append(`data[${index}][${key}]`, value);
            }
        });

        const { data } = await axios.post(
            process.env.REACT_APP_API_URL + API_POST_TIGERCAT_BURNS_POST_BULK_FUEL_DELIVERY + `/${carRppbProjectId}`,
            formData,
            formDataConfig
        );
        dispatch({
            type: POST_TIGERCAT_BURNS_POST_BULK_FUEL_DELIVERY_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: POST_TIGERCAT_BURNS_POST_BULK_FUEL_DELIVERY_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const getBulkFuelDelivery = (carRppbProjectId) => async dispatch => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + API_GET_TIGERCAT_BURNS_GET_BULK_FUEL_DELIVERY + `/${carRppbProjectId}`,
            config
        );
        dispatch({
            type: GET_TIGERCAT_BURNS_GET_BULK_FUEL_DELIVERY_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: GET_TIGERCAT_BURNS_GET_BULK_FUEL_DELIVERY_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const postBiocharProductionFuelUse = (carRppbProjectId, fuelUseData) => async dispatch => {
    try {
        const { data } = await axios.post(
            process.env.REACT_APP_API_URL + API_POST_TIGERCAT_BURNS_POST_BIOCHAR_PRODUCTION_FUEL_USE + `/${carRppbProjectId}`,
            fuelUseData,
            config
        );
        dispatch({
            type: POST_TIGERCAT_BURNS_POST_BIOCHAR_PRODUCTION_FUEL_USE_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: POST_TIGERCAT_BURNS_POST_BIOCHAR_PRODUCTION_FUEL_USE_FAIL,
            payload: err.response.data.detail,
        });
    }
};

export const getBiocharProductionFuelUse = (carRppbProjectId) => async dispatch => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + API_GET_TIGERCAT_BURNS_GET_BIOCHAR_PRODUCTION_FUEL_USE + `/${carRppbProjectId}`,
            config
        );
        dispatch({
            type: GET_TIGERCAT_BURNS_GET_BIOCHAR_PRODUCTION_FUEL_USE_SUCCESS,
            payload: data,
        });
        dispatch(setAlert(data.detail, 'success'));
    } catch (err) {
        dispatch(setAlert(err.response.data.detail, 'error'));
        dispatch({
            type: GET_TIGERCAT_BURNS_GET_BIOCHAR_PRODUCTION_FUEL_USE_FAIL,
            payload: err.response.data.detail,
        });
    }
};