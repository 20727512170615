import { Box, Button, Grid } from '@mui/material';
import { useState } from 'react';
import ChemicalsAndAdditivesDataCapturing from './ChemicalsAndAdditivesDataCapturing';
import WaterDataCupturing from './WaterDataCupturing';
import WoodenProductsDataCapturing from './WoodenProductsDataCapturing';
import PackagingDataCapturing from './PackagingDataCapturing';
import WineAndGrapesDataCapturing from './WineAndGrapesDataCapturing';
import OtherDataCapturing from './OtherDataCapturing';


export default function PurchasedGoodsAndServices() {
    const [selected, setSelected] = useState('Water')
    let categories = ['Water', 'Chemicals & additives', 'Wooden Products', 'Packaging', 'wine & grapes', 'Other']

    const outputComponent = () => {
        if (selected === 'Water') {
            return <WaterDataCupturing />
        } else if (selected === 'Chemicals & additives') {
            return <ChemicalsAndAdditivesDataCapturing />
        } else if (selected === 'Wooden Products') {
            return <WoodenProductsDataCapturing />
        } else if (selected === 'Packaging') {
            return <PackagingDataCapturing />
        } else if (selected === 'wine & grapes') {
            return <WineAndGrapesDataCapturing />
        } else if (selected === 'Other') {
            return <OtherDataCapturing />
        } else {
            return null
        }
    }

    return (
        <>
            <Grid item xs={3}>
                <Box marginTop={2}>
                    {categories.map((c, i) => {
                        return (
                            <Button
                                key={i}
                                variant={c === selected ? 'contained' : 'outlined'}
                                size='large'
                                sx={{ width: '100%', display: 'block', marginBottom: 2.5 }}
                                onClick={() => {
                                    setSelected(c)
                                    localStorage.setItem('catFour', c)
                                }}
                            >
                                {c}
                            </Button>
                        )
                    })
                    }
                </Box>
            </Grid>
            <Grid item xs={9}>
                {outputComponent()}
            </Grid>
        </>
    );
}
