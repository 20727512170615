import { useEffect, useState } from 'react';
import {
    Table, TableBody, TableContainer, TableRow, TableHead, Paper, TableCell, Tabs, Tab, Select, MenuItem, FormControl, InputLabel, Button, TextField
} from '@mui/material';
import { DieselTotalTable } from '../../DieselTotalTable';
import { getStationaryDieselData, postStationaryDieselData, getStationaryDieselDataTotals, geHighestId } from '../../../../../actions/carbonfootprint-rework/01-direct-ghg-emissions-and-removals/01-diesel-actions';
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { getAssets } from '../../../../../actions/carbonfootprint-rework/01-direct-ghg-emissions-and-removals/01-add-asset';
import { directGhgEnmissionsAndRemovalsFileUpload } from '../../../../../actions/carbonfootprint-rework/01-direct-ghg-emissions-and-removals/00-file-upload';

export const DieselDataCapturingStationary = () => {
    const dispatch = useDispatch()

    const dieselData = useSelector((state) => state.directGhgEmissionsAndRemovalsForDiesel.dieselData?.data)
    const dieselDataTotals = useSelector((state) => state.directGhgEmissionsAndRemovalsForDiesel.dieselDataTotals?.data)
    const assetData = useSelector((state) => state.directGhgEmissionsAndRemovalsForAssets.assetData?.data)
    let highestId = useSelector((state) => state.directGhgEmissionsAndRemovalsForDiesel.highestId.max_id)

    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Totals']
    const [month, setMonth] = useState(0);
    const [assetRows, setAssetRows] = useState({ 0: [], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [], 8: [], 9: [], 10: [], 11: [] })

    const year = localStorage.getItem('year')
    const facility = localStorage.getItem('facility')
    const group = localStorage.getItem('group')
    const company = localStorage.getItem('company')

    useEffect(() => {
        dispatch(getStationaryDieselData('stationary', year, facility))
        dispatch(geHighestId('diesel'))
        dispatch(getAssets(facility))
        dispatch(getStationaryDieselDataTotals('stationary', year, facility))
    }, [dispatch, year, facility])

    useEffect(() => {
        if (dieselData && dieselData[month]?.length > 0) setAssetRows({ ...dieselData })
        else setAssetRows({ 0: [], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [], 8: [], 9: [], 10: [], 11: [] })
    }, [dispatch, dieselData, month])

    const handleMonthChange = (event, newValue) => {
        setMonth(newValue);
        if (newValue === 12) {
            dispatch(getStationaryDieselDataTotals('stationary', year, facility))
        } else {
            dispatch(getStationaryDieselData('stationary', year, facility))
            dispatch(geHighestId('diesel'))
        }
    };

    const handleAddAsset = async () => {
        await dispatch(geHighestId('diesel')).then(() => {
            setAssetRows((prevRows) => {
                let highestIdInAssetRows = 0
                for (let key in assetRows) {
                    let sublist = assetRows[key];
                    for (let item of sublist) {
                        highestIdInAssetRows = Math.max(highestId, item.id);
                    }
                }
                if (highestIdInAssetRows >= highestId) {
                    highestId = ++highestIdInAssetRows;
                } else {
                    highestId = ++highestId
                }
                const prevRowsMonth = [...prevRows[month], { id: highestId, asset: '', unit: '', quantity: '', file: '' }]
                prevRows[month] = prevRowsMonth
                return { ...prevRows }
            })
        })
    }

    const handleAssetSelection = (e, month, id) => {
        setAssetRows((prevAssets) => {
            const assetToEdit = prevAssets[month].find((data) => data.id === id)
            assetToEdit['asset'] = e.target.value
            const filteredMonth = prevAssets[month].filter((d) => d.id !== id)
            prevAssets[month] = [...filteredMonth, assetToEdit].sort((a, b) => a.id - b.id)
            return { ...prevAssets }
        })
    }

    const handlUnitSelection = (e, month, id) => {
        setAssetRows((prevUnits) => {
            const unitToEdit = prevUnits[month].find((data) => data.id === id)
            unitToEdit['unit'] = e.target.value
            const filteredMonth = prevUnits[month].filter((d) => d.id !== id)
            prevUnits[month] = [...filteredMonth, unitToEdit].sort((a, b) => a.id - b.id)
            return { ...prevUnits }
        })
    }

    const handleInputChange = (e, month, id) => {
        setAssetRows((prevRows) => {
            const quantityToEdit = prevRows[month].find((data) => data.id === id)
            quantityToEdit['quantity'] = e.target.value
            const filteredMonth = prevRows[month].filter((d) => d.id !== id)
            prevRows[month] = [...filteredMonth, quantityToEdit].sort((a, b) => a.id - b.id)
            return { ...prevRows }
        })
    }

    const handleFileUpload = (e, month, id) => {
        setAssetRows((prevRows) => {
            const fileToEdit = prevRows[month].find((data) => data.id === id)
            fileToEdit['file'] = e.target.files[0]
            const filteredMonth = prevRows[month].filter((d) => d.id !== id)
            prevRows[month] = [...filteredMonth, fileToEdit].sort((a, b) => a.id - b.id)
            return { ...prevRows }
        })
    }

    const handleDieselDataSubmission = () => {
        const removeEmptyMonthValues = (obj) => {
            for (let key in obj) {
                obj[key] = obj[key].filter(item => item.asset !== '' && item.unit !== '' && item.quantity !== '');
                if (obj[key].length === 0) {
                    delete obj[key];
                }
            }
            return obj;
        }
        const clonedAssetRows = removeEmptyMonthValues({ ...assetRows })

        let formData = new FormData();

        Object.keys(clonedAssetRows).forEach((key) => {
            clonedAssetRows[key].forEach(item => {
                if (item.file?.name) {
                    formData.append(item.id, item.file);
                }
            });
        });

        dispatch(postStationaryDieselData(clonedAssetRows, 'stationary', year, company, group, facility)).then(() => {
            dispatch(directGhgEnmissionsAndRemovalsFileUpload(formData, 'diesel-stationary'))
        })
    }

    return (
        <>
            <Tabs
                value={month}
                onChange={handleMonthChange}
                sx={{ width: '100%' }}
                textColor='inherit'
                indicatorColor='secondary'
                variant="scrollable"
                scrollButtons="auto"
            >
                {months.map((m, i) => {
                    return (
                        <Tab key={i} label={m} sx={{ minWidth: '25px' }} />
                    )
                })}
            </Tabs>
            {month === 12 ? <DieselTotalTable dieselDataTotals={dieselDataTotals} months={months} /> :
                <>
                    <TableContainer component={Paper} sx={{ height: 'auto' }}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">
                                        Asset
                                    </TableCell>
                                    <TableCell align="left">
                                        Unit
                                    </TableCell>
                                    <TableCell align="left">
                                        Value
                                    </TableCell>
                                    <TableCell align="left">
                                        Soure Document
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {assetRows[month]?.map((r, index) => {
                                    return (
                                        // assetRows[month] {
                                        <TableRow key={index}>
                                            <TableCell align="left">
                                                <FormControl sx={{ width: '200px' }}>
                                                    <InputLabel id="asset">{!!r.asset ? r.asset : 'Asset'}</InputLabel>
                                                    <Select
                                                        required={true}
                                                        name="Asset"
                                                        value={!!r.asset ? r.asset : 'Asset'}
                                                        onChange={(e) => handleAssetSelection(e, month, r.id)}
                                                        label='Asset'
                                                    >
                                                        {assetData?.map(item => {
                                                            return (
                                                                <MenuItem key={item._id} value={item.asset}>
                                                                    {item.asset}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </Select>

                                                </FormControl>
                                            </TableCell>
                                            <TableCell align="left">
                                                <FormControl sx={{ width: '200px' }}>
                                                    <InputLabel id="asset">{!!r.unit ? r.unit : 'Unit'}</InputLabel>
                                                    <Select
                                                        required={true}
                                                        name="unit"
                                                        value={!!r.unit ? r.unit : 'Unit'}
                                                        onChange={(e) => handlUnitSelection(e, month, r.id)}
                                                        label='Unit'
                                                    >
                                                        {[
                                                            { id: 0, value: 'Litres' },
                                                            { id: 1, value: 'Rands' },
                                                        ].map(item => {
                                                            return (
                                                                <MenuItem key={item.id} value={item.value}>
                                                                    {item.value}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align="left">
                                                <TextField
                                                    required={true}
                                                    fullWidth
                                                    name={'quantity'}
                                                    value={!!r.quantity ? r.quantity : ''}
                                                    onChange={(e) => handleInputChange(e, month, r.id)}
                                                    type="text"
                                                />
                                            </TableCell>
                                            <TableCell align="left">
                                                {r?.file ? <>
                                                    <TextField
                                                        disabled
                                                        name={'Source document'}
                                                        value={!!r?.file?.name ? r.file.name : !!r?.file ? r.file : ''}
                                                        type='text'
                                                    />
                                                    <input
                                                        style={{ display: 'none' }}
                                                        name={'Source document'}
                                                        onChange={(e) => handleFileUpload(e, month, r.id)}
                                                        type='file'
                                                    />
                                                </> : <TextField
                                                    name={'Source document'}
                                                    // value={!!r?.file ? r.file.name : ''}
                                                    onChange={(e) => handleFileUpload(e, month, r.id)}
                                                    type='file'
                                                />}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Button variant="contained" sx={{ m: 2 }} onClick={handleAddAsset}>Add fuel entry</Button>
                    <Button variant="contained" sx={{ m: 2 }} onClick={handleDieselDataSubmission}>Submit data</Button>
                </>}
        </>
    )
}

export default DieselDataCapturingStationary;
