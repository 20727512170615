import React, { useEffect } from 'react';
import AddItem from '../../../../carbonfootprinting/ItemAdd';
import {
    Box,
    Table, TableBody, TableContainer, TableRow, TableHead, TableCell, Paper
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AddPetrolCardHolder, getPetrolCardHolders } from '../../../../../../actions/carbonfootprint-rework/03-indirect-ghg-emissions-from-transportation/03-petrol-card-holder-actions';

export default function PetrolCardHolderList() {
    // { farmId }
    const dispatch = useDispatch()
    const { petrolCardHolders } = useSelector(state => state.petrolCardHolderReducer)
    const facility = localStorage.getItem('facility')
    const year = localStorage.getItem('year')
    const group = localStorage.getItem('group')
    const company = localStorage.getItem('company')

    useEffect(() => {
        dispatch(getPetrolCardHolders(facility))
    }, [dispatch, facility])

    const formFields = [
        { name: 'vehicleRegistration', label: 'Vehicle Registration', type: 'text' },
        { name: 'vehicleType', label: 'Vehicle Type', type: 'text' },
        { name: 'cardHolder', label: 'Card Holder', type: 'text' }

    ];

    const initialState = formFields.reduce((obj, field) => {
        obj[field.name] = '';
        return obj;
    }, {});


    const handleFormSubmit = data => {
        dispatch(AddPetrolCardHolder(data, year, company, group, facility)).then(() => {
            dispatch(getPetrolCardHolders(facility))
        })
    };

    const columns = [
        {
            field: 'vehicleRegistration', headerName: 'Vehicle Registration',
            flex: 1,

        },
        {
            field: 'vehicleType', headerName: 'Vehicle Type',
            flex: 1,
        },
        {
            field: 'cardHolder', headerName: 'Card Holder',
            flex: 1,
        },
        {
            field: 'created_at', headerName: 'Date Created',
            flex: 1
        }
    ]

    return (
        <Box sx={{ width: '100%' }} mt={5}>
            <Box sx={{ height: '100%' }}>
                <AddItem
                    formFields={formFields}
                    initialState={initialState}
                    onFormSubmit={handleFormSubmit}
                    items={[]}
                    addTitle='Add New Petrol Card Holder'
                />
                <TableContainer component={Paper} sx={{ height: 'auto' }}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {columns.map((c, i) => {
                                    return (

                                        <TableCell key={i} align="left">
                                            {c.headerName}
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {petrolCardHolders?.data?.map((d, i) => {
                                return (
                                    <TableRow key={i}>
                                        <TableCell align="left">
                                            {d.vehicleRegistration}
                                        </TableCell>
                                        <TableCell align="left">
                                            {d.vehicleType}
                                        </TableCell>
                                        <TableCell align="left">
                                            {d.cardHolder}
                                        </TableCell>
                                        <TableCell align="left">
                                            {/* {new Date(d.createdAt).toISOString().split('T')[0]} */}
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
}
